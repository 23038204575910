import { useCallback } from 'react';

function FilterInput({searchTerm, setSearchTerm }) {
  
   const handleDataFilter = useCallback(
    (event) => {
      setSearchTerm(event.target.value);
    },
    [setSearchTerm]
  );

 

  return (
    <>  
    <div>
      <input id='searchinput' type="text" value={searchTerm} onChange={handleDataFilter} placeholder='Search for keyword...'/>
      
    </div>
    </>
  );
}

export default FilterInput;