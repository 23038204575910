import Accordion from './Accordion';
import data from "./dat.json";

function App() {
  return (
    <>  
     <div className="tool-body">
    
    <Accordion data = {data}/>
    </div> 
    </>
  );
}

export default App;