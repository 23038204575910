
import { csv } from 'd3';
import cities from "./cities.csv";
import { useState, useEffect } from 'react';

export const useCities = () => {

  const row = d => {
  d.lat = +d.lat;
  d.lng = +d.lng;
  return d;
};

const [thisDat, setData] = useState(null);

 useEffect(() => {

     csv(cities, row).then(setData)
   }, []);  

return thisDat
};


