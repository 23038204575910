import React from 'react';

import { useWorldAtlas } from './useWorldAtlas';
import { useCities } from './useCities';
import { Marks } from './Marks';



const width = 960;
const height = 500;

const Map = ({data}) => {
  const worldAtlas = useWorldAtlas();
  const cities = useCities();
  
if (!worldAtlas || !cities) {
    return <pre>Loading...</pre>;
  }

  let filterValues = [];

  filterValues = data.map(obj => obj.city);
  const filteredData = data ? cities.filter(d => filterValues.includes(d['city'])) : cities;

  return (
    <svg width={width} height={height}>
      <Marks worldAtlas={worldAtlas} cities={filteredData} />
    </svg>
  );
};



export default Map;
