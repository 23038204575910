import * as React from 'react';
import { useState } from "react";

import {styled, Card, CardHeader, CardContent, CardActions, Collapse, IconButton, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterInput from './FilterInput';
import Map from './Map';

 

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export default function Accordion({data}) {


  const [expandedId, setExpandedId] = React.useState(-1);
  const [expandedId2, setExpandedId2] = React.useState(-1);
  const [expandedId3, setExpandedId3] = React.useState(-1);


  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
    setExpandedId2(expandedId2 !== i ? -1 : i);
    setExpandedId3(expandedId3 !== i ? -1 : i);
  };

  
  const handleExpandClick2 = (i) => {
    setExpandedId2(expandedId2 === i ? -1 : i);
    setExpandedId(expandedId !== i ? -1 : i);
    setExpandedId3(expandedId3 !== i ? -1 : i);
  };

  
  const [searchTerm, setSearchTerm] = useState("");
  
  const handleExpandClick3 = (i) => {
    setExpandedId3(expandedId3 === i ? -1 : i);
    setExpandedId(expandedId !== i ? -1 : i);
    setExpandedId2(expandedId2 !== i ? -1 : i);
  };

  
  const newDat = data
       .filter((val) => {
         if(searchTerm === ""){
           return val;
         }else if(val.title.toLowerCase().includes(searchTerm)){
           return val;
         }else if(val.regulator.toLowerCase().includes(searchTerm)){
          return val;
        }else if(val.publication_date.toLowerCase().includes(searchTerm)){
          return val;
        }else if(val.summary.toLowerCase().includes(searchTerm)){
          return val;
        }else if(val.country.toLowerCase().includes(searchTerm)){
          return val;
        }else if(val.city.toLowerCase().includes(searchTerm)){
          return val;
        }else if(val.region.toLowerCase().includes(searchTerm)){
          return val;
        }else if(val.status.toLowerCase().includes(searchTerm)){
          return val;
        }});
        
  

  return (
    <>
    <div>
    <Map data = {newDat}/>
    <FilterInput  searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
    </div>
    <div className="templateContainer">
      <div className="template_Container">
        {newDat
            .map((val, i) => {
              return(
      <Card key={val.id} className="card2" sx={{ width:'80%'}} square='true' >
      <CardHeader 
      
        title={<Typography><h1>{val.title}</h1></Typography>}
        subheader={<Typography className='subheader'>{val.country},  {val.publication_date}</Typography>}
      />
      
      <CardActions >
      <Typography  className="cardaction">Key info</Typography>
        <ExpandMore
          expand={expandedId3}
          onClick={() => handleExpandClick3(i)}
          aria-expanded={expandedId3 === i}
          aria-label="show more"
        >
          <ExpandMoreIcon className='expand' />
        </ExpandMore>
        <Typography  className="cardaction">Summary</Typography>
        <ExpandMore
          expand={expandedId}
          onClick={() => handleExpandClick(i)}
          aria-expanded={expandedId === i}
          aria-label="show more"
        >
          <ExpandMoreIcon className='expand' />
        </ExpandMore>
        
        <Typography className="cardaction">Links</Typography>
        <ExpandMore
          expand={expandedId2}
          onClick={() => handleExpandClick2(i)}
          aria-expanded={expandedId2 === i}
          aria-label="show more"
        >
          <ExpandMoreIcon className="expand"/>
        </ExpandMore>
      
      </CardActions>
      <Collapse in={expandedId3 === i} timeout="auto" unmountOnExit>
      <CardContent >
      <Typography><h3>Key info</h3></Typography>
        <Typography>Regulator: {val.regulator}</Typography>
        <Typography>Region:{val.region}</Typography>
        <Typography>Status: {val.status}</Typography> 
      </CardContent>
      </Collapse>
      <Collapse in={expandedId === i} timeout="auto" unmountOnExit>
        <CardContent>
        <Typography><h3>Summary</h3></Typography>
          <Typography>
          {val.summary}
          </Typography>
        </CardContent>
      </Collapse>
      <Collapse in={expandedId2 === i} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography><h3>Links</h3></Typography>
          <Typography>
          <a href={val.link}>{val.link}</a>
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
            )
          })
      }
    </div></div>
    </>
  );
}